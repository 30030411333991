.gradient0{
    background-color: #FBAB7E;
background-image: linear-gradient(to right, #FBAB7E 0%, #F7CE68 100%);
}
.gradient1 {
    background: linear-gradient(to right,#ff4e50,#f9d423);
}
.gradient2{
    background: linear-gradient(to right,#5f2c82,#49a09d);
}
.gradient3{
    background-color: #FBAB7E;
background-image: linear-gradient(to right, #FBAB7E 0%, #F7CE68 100%);
}
.statLabel{
    margin-bottom:0px !important;
    color: rgba(255, 255, 255, 0.7);
}
.stat{
    color: white;
}
