.cardSize {
    width: 280px;
    height: 200px;
}

.Astrophile {
    background: #00d2ff;
    /* fallback for old browsers */
    background: -webkit-linear-gradient(to right, #3a7bd5, #00d2ff);
    /* Chrome 10-25, Safari 5.1-6 */
    background: linear-gradient(to right, #3a7bd5, #00d2ff);
    /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
}

.Balter {
    background: #f2709c;
    /* fallback for old browsers */
    background: -webkit-linear-gradient(to right, #ff9472, #f2709c);
    /* Chrome 10-25, Safari 5.1-6 */
    background: linear-gradient(to right, #ff9472, #f2709c);
    /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
}

.Bel-esprit {
    background: #c21500;
    /* fallback for old browsers */
    background: -webkit-linear-gradient(to right, #ffc500, #c21500);
    /* Chrome 10-25, Safari 5.1-6 */
    background: linear-gradient(to right, #ffc500, #c21500);
    /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
}

.Caim {
    background: #DE6262;
    /* fallback for old browsers */
    background: -webkit-linear-gradient(to right, #FFB88C, #DE6262);
    /* Chrome 10-25, Safari 5.1-6 */
    background: linear-gradient(to right, #FFB88C, #DE6262);
    /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
}

.Clinquant {
    background: #1A2980;
    /* fallback for old browsers */
    background: -webkit-linear-gradient(to right, #26D0CE, #1A2980);
    /* Chrome 10-25, Safari 5.1-6 */
    background: linear-gradient(to right, #26D0CE, #1A2980);
    /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
}

.Insouciant {
    background: #4b6cb7;
    /* fallback for old browsers */
    background: -webkit-linear-gradient(to right, #182848, #4b6cb7);
    /* Chrome 10-25, Safari 5.1-6 */
    background: linear-gradient(to right, #182848, #4b6cb7);
    /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
}

.Komorebi {
    background: #e43a15;
    /* fallback for old browsers */
    background: -webkit-linear-gradient(to right, #e65245, #e43a15);
    /* Chrome 10-25, Safari 5.1-6 */
    background: linear-gradient(to right, #e65245, #e43a15);
    /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
}

.Metanoia {
    background: #EC6F66;
    /* fallback for old browsers */
    background: -webkit-linear-gradient(to right, #F3A183, #EC6F66);
    /* Chrome 10-25, Safari 5.1-6 */
    background: linear-gradient(to right, #F3A183, #EC6F66);
    /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
}

.Moonglade {
    background: #7474BF;
    /* fallback for old browsers */
    background: -webkit-linear-gradient(to right, #348AC7, #7474BF);
    /* Chrome 10-25, Safari 5.1-6 */
    background: linear-gradient(to right, #348AC7, #7474BF);
    /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
}

.Novaturient {
    background: #24C6DC;
    /* fallback for old browsers */
    background: -webkit-linear-gradient(to right, #514A9D, #24C6DC);
    /* Chrome 10-25, Safari 5.1-6 */
    background: linear-gradient(to right, #514A9D, #24C6DC);
    /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
}

.Orenda {
    background: #134E5E;
    /* fallback for old browsers */
    background: -webkit-linear-gradient(to right, #71B280, #134E5E);
    /* Chrome 10-25, Safari 5.1-6 */
    background: linear-gradient(to right, #71B280, #134E5E);
    /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
}

.Saorsa {
    background: #FF8008;
    /* fallback for old browsers */
    background: -webkit-linear-gradient(to right, #FFC837, #FF8008);
    /* Chrome 10-25, Safari 5.1-6 */
    background: linear-gradient(to right, #FFC837, #FF8008);
    /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
}

.Yugen {
    background: #EB3349;
    /* fallback for old browsers */
    background: -webkit-linear-gradient(to right, #F45C43, #EB3349);
    /* Chrome 10-25, Safari 5.1-6 */
    background: linear-gradient(to right, #F45C43, #EB3349);
    /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
}