@import url(https://fonts.googleapis.com/css2?family=Raleway:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Alegreya:wght@400;800;900&display=swap);
html {
  scroll-behavior: smooth;
}

body {
  margin: 0;
  /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif; */
  font-family: 'Raleway', sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

/* .sidemenu{
  background-color: #292b2c !important;
}
.sidemenuicon{
  color: white !important;
}
.sidemenutext{
  color: white !important;
} */
.initials {
    background-color: black;
    height: 100px;
    width: 100px;
    border-radius: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
}

.initialsSmall {
    background-color: black;
    height: 40px;
    width: 40px;
    border-radius: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
}

.imageEdit {
    background-color: rgba(255, 255, 255, 0.9);
    position: absolute;
    height: 100px;
    width: 100px;
    border-radius: 50px;
    display: none;
    justify-content: center;
    align-items: center;
    color: black;
    cursor: pointer;
}

.initials:hover>.imageEdit {
    display: flex;
}

.initialsBig {
    background-color: black;
    height: 200px;
    width: 200px;
    border-radius: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
}

.profileImageBig {
    height: 200px;
    width: 200px;
    border-radius: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
    object-fit: contain;
    background-color: black;
    border: 1px solid black;
}

.profileImage {
    height: 100px;
    width: 100px;
    border-radius: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
    object-fit: contain;
    background-color: black;
    border: 1px solid black;
}

.profileImageSmall {
    height: 40px;
    width: 40px;
    border-radius: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    object-fit: contain;
    background-color: black;
    border: 1px solid black;
}

.separator {
    display: flex;
    align-items: center;
    text-align: center;
}

.separator::before, .separator::after {
    content: '';
    flex: 1 1;
    border-bottom: 1px solid #000;
}

.separator::before {
    margin-right: .25em;
}

.separator::after {
    margin-left: .25em;
}

.loginInput {
    outline: none;
    border-width: 0 0 2px !important;
    border-color: grey !important;
    border-radius: 0px !important;

}
.loginInput:focus{
    outline-width: 0;
    border-color: black !important;
}
.title{
    font-size: 4em;
}
.nav{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-left: 24px;
    margin-right: 24px;
    
}
.cardSize {
    width: 280px;
    height: 200px;
}

.Astrophile {
    background: #00d2ff;
    /* fallback for old browsers */
    /* Chrome 10-25, Safari 5.1-6 */
    background: linear-gradient(to right, #3a7bd5, #00d2ff);
    /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
}

.Balter {
    background: #f2709c;
    /* fallback for old browsers */
    /* Chrome 10-25, Safari 5.1-6 */
    background: linear-gradient(to right, #ff9472, #f2709c);
    /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
}

.Bel-esprit {
    background: #c21500;
    /* fallback for old browsers */
    /* Chrome 10-25, Safari 5.1-6 */
    background: linear-gradient(to right, #ffc500, #c21500);
    /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
}

.Caim {
    background: #DE6262;
    /* fallback for old browsers */
    /* Chrome 10-25, Safari 5.1-6 */
    background: linear-gradient(to right, #FFB88C, #DE6262);
    /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
}

.Clinquant {
    background: #1A2980;
    /* fallback for old browsers */
    /* Chrome 10-25, Safari 5.1-6 */
    background: linear-gradient(to right, #26D0CE, #1A2980);
    /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
}

.Insouciant {
    background: #4b6cb7;
    /* fallback for old browsers */
    /* Chrome 10-25, Safari 5.1-6 */
    background: linear-gradient(to right, #182848, #4b6cb7);
    /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
}

.Komorebi {
    background: #e43a15;
    /* fallback for old browsers */
    /* Chrome 10-25, Safari 5.1-6 */
    background: linear-gradient(to right, #e65245, #e43a15);
    /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
}

.Metanoia {
    background: #EC6F66;
    /* fallback for old browsers */
    /* Chrome 10-25, Safari 5.1-6 */
    background: linear-gradient(to right, #F3A183, #EC6F66);
    /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
}

.Moonglade {
    background: #7474BF;
    /* fallback for old browsers */
    /* Chrome 10-25, Safari 5.1-6 */
    background: linear-gradient(to right, #348AC7, #7474BF);
    /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
}

.Novaturient {
    background: #24C6DC;
    /* fallback for old browsers */
    /* Chrome 10-25, Safari 5.1-6 */
    background: linear-gradient(to right, #514A9D, #24C6DC);
    /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
}

.Orenda {
    background: #134E5E;
    /* fallback for old browsers */
    /* Chrome 10-25, Safari 5.1-6 */
    background: linear-gradient(to right, #71B280, #134E5E);
    /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
}

.Saorsa {
    background: #FF8008;
    /* fallback for old browsers */
    /* Chrome 10-25, Safari 5.1-6 */
    background: linear-gradient(to right, #FFC837, #FF8008);
    /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
}

.Yugen {
    background: #EB3349;
    /* fallback for old browsers */
    /* Chrome 10-25, Safari 5.1-6 */
    background: linear-gradient(to right, #F45C43, #EB3349);
    /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
}
.gradient0{
    background-color: #FBAB7E;
background-image: linear-gradient(to right, #FBAB7E 0%, #F7CE68 100%);
}
.gradient1 {
    background: linear-gradient(to right,#ff4e50,#f9d423);
}
.gradient2{
    background: linear-gradient(to right,#5f2c82,#49a09d);
}
.gradient3{
    background-color: #FBAB7E;
background-image: linear-gradient(to right, #FBAB7E 0%, #F7CE68 100%);
}
.statLabel{
    margin-bottom:0px !important;
    color: rgba(255, 255, 255, 0.7);
}
.stat{
    color: white;
}

.correct{
    background-color: #4caf50 !important;
    color: white;
}
.incorrect{
    background-color: #aa2e25 !important;
    color: white;
}
.noanswer{
    background-color: #aa2e25 !important;
    color: white;
}
.question{
    font-weight: bold;
}
.testheading{
    font-family: 'Sofia', cursive;
    text-transform: capitalize;
}
.testImage{
    position: absolute;
    right: 10px;
    top: 10px;
    height: 6em;
    width: 10em;
    transition: transform .5s ease;
    transform-origin: top right;
    z-index: 999;
    box-shadow: 5px 10px 18px;
}
.testImage:hover{
    transform: scale(2);
    transform-origin: top right;
}

.ctab.active{
    color: #343A3F !important;
    background-color: white !important;
    border-radius: 30px !important;
}
.ctab{
    color: #ffffff !important;
    margin-right: 4px;
    margin-left: 4px;
}
.ctab:hover{
    border-radius: 30px !important;
}
.ctabs{
    background-color: #343A3F;
    border-bottom: 0px !important;
    margin-top: 10px;
    border-radius: 100px;
}

