.question{
    font-weight: bold;
}
.testheading{
    font-family: 'Sofia', cursive;
    text-transform: capitalize;
}
.testImage{
    position: absolute;
    right: 10px;
    top: 10px;
    height: 6em;
    width: 10em;
    transition: transform .5s ease;
    transform-origin: top right;
    z-index: 999;
    box-shadow: 5px 10px 18px;
}
.testImage:hover{
    transform: scale(2);
    transform-origin: top right;
}
