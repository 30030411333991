.ctab.active{
    color: #343A3F !important;
    background-color: white !important;
    border-radius: 30px !important;
}
.ctab{
    color: #ffffff !important;
    margin-right: 4px;
    margin-left: 4px;
}
.ctab:hover{
    border-radius: 30px !important;
}
.ctabs{
    background-color: #343A3F;
    border-bottom: 0px !important;
    margin-top: 10px;
    border-radius: 100px;
}
