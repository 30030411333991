@import url('https://fonts.googleapis.com/css2?family=Alegreya:wght@400;800;900&display=swap');
.initials {
    background-color: black;
    height: 100px;
    width: 100px;
    border-radius: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
}

.initialsSmall {
    background-color: black;
    height: 40px;
    width: 40px;
    border-radius: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
}

.imageEdit {
    background-color: rgba(255, 255, 255, 0.9);
    position: absolute;
    height: 100px;
    width: 100px;
    border-radius: 50px;
    display: none;
    justify-content: center;
    align-items: center;
    color: black;
    cursor: pointer;
}

.initials:hover>.imageEdit {
    display: flex;
}

.initialsBig {
    background-color: black;
    height: 200px;
    width: 200px;
    border-radius: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
}

.profileImageBig {
    height: 200px;
    width: 200px;
    border-radius: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
    object-fit: contain;
    background-color: black;
    border: 1px solid black;
}

.profileImage {
    height: 100px;
    width: 100px;
    border-radius: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
    object-fit: contain;
    background-color: black;
    border: 1px solid black;
}

.profileImageSmall {
    height: 40px;
    width: 40px;
    border-radius: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    object-fit: contain;
    background-color: black;
    border: 1px solid black;
}

.separator {
    display: flex;
    align-items: center;
    text-align: center;
}

.separator::before, .separator::after {
    content: '';
    flex: 1;
    border-bottom: 1px solid #000;
}

.separator::before {
    margin-right: .25em;
}

.separator::after {
    margin-left: .25em;
}

.loginInput {
    outline: none;
    border-width: 0 0 2px !important;
    border-color: grey !important;
    border-radius: 0px !important;

}
.loginInput:focus{
    outline-width: 0;
    border-color: black !important;
}